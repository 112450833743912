.teaser.cmp-teaser-bg-grey {
  background: #f8f8fd;
}

.teaser.cmp-teaser-bg-blue {
  background: #eef7f7;
}

.teaser.cmp-teaser-bg-grey, .teaser.cmp-teaser-bg-blue {
  padding: 1rem;
}

main .cmp-container .aem-Grid .teaser.cmp-teaser-bg-grey, main .cmp-container .aem-Grid .teaser.cmp-teaser-bg-blue {
  margin: 3.125rem 0;
}

.teaser.cmp-teaser-center {
  text-align: center;
}

@media screen and (width <= 1024px) {
  .teaser.cmp-teaser-center .cmp-teaser-bloc {
    margin: auto;
  }
}

.teaser.cmp-teaser-center .cmp-teaser-image, .teaser.cmp-teaser-center .cmp-teaser-arrow-link {
  margin: auto;
}

.teaser.cmp-teaser-center .cmp-teaser ul {
  text-align: left;
}

.teaser.cmp-teaser-center .cmp-teaser-title {
  padding-left: 0;
}

.teaser.cmp-teaser-frame .cmp-teaser .cmp-teaser-content .cmp-teaser-bloc, .teaser.cmp-teaser-frame .cmp-teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button {
  border: 1px solid #dadadd;
  border-radius: .25rem;
  margin: .5rem;
  padding: 2rem 1rem;
}

@media screen and (width <= 1024px) {
  .teaser.cmp-teaser-frame .cmp-teaser .cmp-teaser-content .cmp-teaser-bloc, .teaser.cmp-teaser-frame .cmp-teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button {
    margin: .5rem auto;
  }
}

.teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))) {
  border-right: 1px solid #8b8a93;
}

@media screen and (width <= 1024px) {
  .teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))) {
    border: none;
  }
}

.teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))).image-right, .teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))).image-left {
  border-bottom: 1px solid #8b8a93;
  border-right: none;
}

@media screen and (width <= 1024px) {
  .teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))).image-right, .teaser.cmp-teaser-separator .cmp-teaser-bloc:not(:not(:has( ~ .cmp-teaser-bloc))).image-left {
    border: none;
  }
}

.teaser.cmp-teaser-list ul {
  padding: 0;
}

.teaser.cmp-teaser-list ul li {
  background: url("../../../../../resources/images/icon-list.png") no-repeat;
  padding: 0 0 1.5rem 2.25rem;
  list-style: none;
}

.teaser.cmp-teaser-list .teaser-banner li {
  background: url("../../../../../resources/images/icon-list-white.png") no-repeat;
}

.teaser {
  padding: 3.125rem 0;
}

@media screen and (width <= 1024px) {
  .teaser {
    padding: 1rem 0;
  }
}

.teaser .cmp-teaser-title {
  color: #252339;
  padding-bottom: 1.875rem;
  padding-left: 1rem;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.75rem;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-title {
    width: 80%;
    margin: auto;
  }
}

@media screen and (width <= 768px) {
  .teaser .cmp-teaser-title {
    width: 100%;
    padding-left: 0;
  }
}

.teaser .cmp-teaser-content {
  justify-content: center;
  align-items: stretch;
  display: flex;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content {
    flex-direction: column;
  }
}

.teaser .cmp-teaser-content .button {
  padding: .625rem 0;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 1rem;
  display: flex;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc {
    width: 80%;
    margin: auto;
  }
}

@media screen and (width <= 768px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc {
    width: 100%;
    padding: 1rem 0;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-image, .teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-image img {
  border-radius: .375rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-label {
  color: #3e3d48;
  text-transform: uppercase;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-bloctitle {
  color: #252339;
  padding-top: 1rem;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 700;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-subtitle {
  color: #3e3d48;
  font-weight: 700;
  line-height: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-blocdescription {
  line-height: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-blocdescription b {
  font-weight: 700;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-arrow-link {
  width: fit-content;
  margin: 0;
  padding-top: .25rem;
  text-decoration: none;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-arrow-link:after {
  content: " →";
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-arrow-link:hover {
  text-decoration: underline;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-button {
  width: 60%;
  margin: auto;
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc .cmp-teaser-button {
    width: 100%;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-center-alignment .cmp-teaser-image, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left-alignment .cmp-teaser-image {
  width: 3.75rem;
  height: 3.75rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-center-alignment .cmp-teaser-label, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left-alignment .cmp-teaser-label {
  padding-top: .5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-center-alignment .cmp-teaser-blocdescription, .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left-alignment .cmp-teaser-blocdescription {
  flex: 1;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-center-alignment {
  text-align: center;
}

@media screen and (width >= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-center-alignment {
    max-width: 500px;
  }
}

@media screen and (width <= 1024px) {
  .teaser .cmp-teaser-content .cmp-teaser-bloc.picto-center-alignment {
    margin: auto;
  }
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-center-alignment .cmp-teaser-image {
  margin: auto;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-center-alignment .cmp-teaser-bloctitle {
  font-size: 1.5rem;
  line-height: 2rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-center-alignment .cmp-teaser-blocdescription {
  padding: 1rem 0;
  font-size: .875rem;
  line-height: 1.25rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left-alignment {
  justify-content: flex-start;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left-alignment .cmp-teaser-bloctitle {
  padding-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.picto-left-alignment .cmp-teaser-blocdescription {
  font-size: 1rem;
  line-height: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-link .cmp-teaser-text, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-text {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-link .cmp-teaser-image, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-image {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
  height: 18.75rem;
  margin-bottom: .25rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-link .cmp-teaser-label, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-label {
  text-transform: none;
  padding: 0 0 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-link .cmp-teaser-bloctitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-bloctitle {
  text-transform: none;
  padding-top: .5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-link .cmp-teaser-subtitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-subtitle {
  padding-bottom: .438rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-link .cmp-teaser-blocdescription, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-blocdescription {
  padding-bottom: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-label, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-bloctitle, .teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-subtitle {
  text-align: center;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-blocdescription {
  padding-top: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-blocdescription ul li {
  background: url("../../../../../resources/images/icon-list.png") no-repeat;
  padding-left: 2.25rem;
  list-style: none;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-blocdescription ul li:not(:last-child) {
  padding-bottom: 1.5rem;
}

.teaser .cmp-teaser-content .cmp-teaser-bloc.image-with-button .cmp-teaser-button {
  padding-left: .5rem;
  padding-right: .5rem;
}
/*# sourceMappingURL=_teaser_type.css.map */
