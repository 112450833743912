@import '../../../../common/teaser/_teaser.scss';

.teaser {
  .cmp-teaser {
    &-content {
      .cmp-teaser {
        &-bloc {
          // common picto
          &.picto-center-alignment,
          &.picto-left-alignment {
            .cmp-teaser {
              &-image {
                height: 3.75rem;
                width: 3.75rem;
              }

              &-label {
                padding-top: 0.5rem;
              }

              &-blocdescription {
                flex: 1;
              }
            }
          }

          // picto center
          &.picto-center-alignment {
            text-align: center;

            @media screen and (min-width: $tablet) {
              max-width: 500px;
            }

            @media screen and (max-width: $tablet) {
              margin: auto;
            }

            .cmp-teaser {
              &-image {
                margin: auto;
              }

              &-bloctitle {
                font-size: 1.5rem;
                line-height: 2rem;
              }
              
              &-blocdescription {
                font-size: 0.875rem;
                line-height: 1.25rem;
                padding: 1rem 0;
              }
            }
          }

          // picto-left
          &.picto-left-alignment {
            justify-content: flex-start;

            .cmp-teaser {
              &-bloctitle {
                font-size: 1.125rem;
                line-height: 1.5rem;
                padding-bottom: 1rem;
              }
              
              &-blocdescription {
                font-size: 1rem;
                line-height: 1.5rem;
              }
            }
          }

          // common image
          &.image-with-link,
          &.image-with-button {
            .cmp-teaser {
              &-text {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: space-between;
              }

              &-image {
                margin-bottom: 0.25rem;
                height: 18.75rem;
                object-fit: cover;
                object-position: center;
                width: 100%;
              }

              &-label {
                font-size: 1.125rem;
                font-weight: 600;
                line-height: 1.5rem;
                padding: 0 0 1rem;
                text-transform: none;
              }

              &-bloctitle {
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 2rem;
                padding-top: 0.5rem;
                text-transform: none;
              }

              &-subtitle {
                padding-bottom: 0.438rem;
              }

              &-blocdescription {
                padding-bottom: 1.5rem;
              }
            }
          }

          // image-with-button
          &.image-with-button {
            .cmp-teaser {

              &-label {
                text-align: center;
              }

              &-bloctitle,
              &-subtitle {
                text-align: center;
              }

              &-blocdescription {
                padding-top: 1.5rem;

                ul {
                  li {
                    background: url('../../../../../resources/images/icon-list.png') left top no-repeat;
                    list-style: none;
                    padding-left: 2.25rem;

                    &:not(:last-child) {
                      padding-bottom: 1.5rem;
                    }
                  }
                }
              }

              &-button {
                padding-left: .5rem;
                padding-right: .5rem;
              }
            }
          }
        }
      }
    }
  }
}

